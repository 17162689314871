<template>
  <!-- This example requires Tailwind CSS v2.0+ -->

  <div class="">
    <div class="relative overflow-hidden">
      <div class="absolute inset-y-0 h-full w-full" aria-hidden="true">
        <div class="relative h-full">
          <svg class="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full" width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="e229dbec-10e9-49ee-8ec3-0286ca089edf" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)" />
          </svg>
          <svg class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="d2a68204-c383-44b1-b99f-42ccff4e5365" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)" />
          </svg>
        </div>
      </div>

      <div class="relative pt-6 pb-16 sm:pb-24">
        <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
          <div class="text-center">
            <h1 class="hidden text-6xl tracking-tight font-extrabold text-gray-900 sm:text-6xl md:text-7xl">Kairo</h1>
            <img class="mx-auto relative max-w-auto md:max-w-3xl" src="@/assets/hp/kairo-logo.png">
            <h2 class="mt-10 text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span class="block xl:inline">Disconnect from the clock</span><br />
              <span class="block text-green-400 xl:inline">reconnect with the flow</span>
            </h2>
            <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Engaging and straightforward yet delightful time, habit and goal tracking dashboard.
            </p>
            <div class="pt-10 max-w-xl mx-auto px-4 sm:px-6 lg:px-8">
              <a
                href="https://app.getkairo.com/signup"
                class="inline-flex text-xl font-medium border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50"
                style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
              >
                Start trial
              </a>
              <p class="text-center text-sm text-green-700 m-2">
                Join <strong>312</strong> users who started using Kairo in November.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="relative">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 mb-10">
          <img class="mx-auto relative rounded-lg shadow-xl" src="@/assets/hp/kairoScreen.png">
        </div>
      </div>

    </div>
  </div>

  <!-- Features -->
  <div class="py-16 bg-gray-50 overflow-hidden lg:py-24" id="features">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">

      <div class="relative">
        <h2 class="text-5xl font-extrabold text-gray-900 sm:text-center">
          Features
        </h2>
        <p class="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
          Track your time, habits and goals on one flexible dashboard.
        </p>
      </div>

      <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="relative">
          <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
            A better way to track your time
          </h3>
          <p class="mt-3 text-lg text-gray-500">
            Effortless and delightful way to track your time with a personal touch.
          </p>

          <dl class="mt-10 space-y-10">
            <div class="relative">
              <dt>
                <p class="text-lg leading-6 font-medium text-gray-900">Time blocks always at your hand</p>
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Easily switch between blocks with just one click.
              </dd>
            </div>

            <div class="relative">
              <dt>
                <p class="text-lg leading-6 font-medium text-gray-900">See how much time is left</p>
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Whenever you get back to work, see immediately how much time is left.
              </dd>
            </div>

            <div class="relative">
              <dt>
                <p class="text-lg leading-6 font-medium text-gray-900">Colourful and personal</p>
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Choose from twenty-two colours and different block faces, whatever fits the project or your current mood.
              </dd>
            </div>
          </dl>
        </div>

        <div class="mt-10 relative lg:mt-0 rounded-lg shadow-xl p-2 bg-white" aria-hidden="true">
          <img class="relative mx-auto" width="676" src="@/assets/hp/featureBlocks.gif">
          <!-- Faces -->
          <div class="">
            <div class="mt-2 text-center grid grid-cols-4">

              <div v-for="faceOption in faces" v-bind:key="faceOption.value"
                 class="col-span-1 mx-auto rounded-full h-24 w-24 m-2 focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
              >
                <BlockFace
                  v-bind:type="faceOption.value"
                  v-bind:isActive="true"
                  v-bind:isPreview="true"
                ></BlockFace>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="relative mt-12 sm:mt-16 lg:mt-24">
        <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div class="lg:col-start-2">
            <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Habits and goals tracking
            </h3>
            <p class="mt-3 text-lg text-gray-500">
              Good habits can be hard to start, even harder it is to keep them. It is easier when you see them every day on your dashboard.
            </p>

            <dl class="mt-10 space-y-10">
              <div class="relative">
                <dt>
                  <p class="text-lg leading-6 font-medium text-gray-900">Any habits or goals</p>
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Add a block, set the goal, add to it as you go throughout the day. It is that simple.
                </dd>
              </div>

              <div class="relative">
                <dt>
                  <p class="text-lg leading-6 font-medium text-gray-900">Valuable insights</p>
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Get insights into when you are advancing with your habits and goals. For example, when is it that you are drinking water or exercising.
                </dd>
              </div>
            </dl>
          </div>

          <div class="mt-10 relative lg:mt-0 lg:col-start-1 rounded-lg shadow-xl p-2 bg-white">
            <img class="relative mx-auto" width="924" src="@/assets/hp/featureHabits.png">
          </div>
        </div>
      </div>

      <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="relative">
          <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
            Quit bad habits, see your progress
          </h3>
          <p class="mt-3 text-lg text-gray-500">
            Quiting a bad habit is tough, stay in the loop every day.
          </p>


          <dl class="mt-10 space-y-10">
            <div class="relative">
              <dt>
                <p class="text-lg leading-6 font-medium text-gray-900">Your progress, right in front of you</p>
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Keep your long term habits in front of you to be constantly reminded you are doing well.
              </dd>
            </div>

            <div class="relative">
              <dt>
                <p class="text-lg leading-6 font-medium text-gray-900">Add motivation "counters"</p>
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                See how much money you have saved since you have quit your old habit. Counters are flexible, so you can add anything that will keep your motivation going.
              </dd>
            </div>

          </dl>
        </div>

        <div class="mt-10 relative lg:mt-0 p-2 rounded-lg shadow-xl p-2 bg-white">
          <img class="relative mx-auto" width="924" src="@/assets/hp/featureProgress.png">
        </div>
      </div>

      <div class="relative mt-12 sm:mt-16 lg:mt-24">
        <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div class="lg:col-start-2">
            <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Deep work and public status
            </h3>
            <p class="mt-3 text-lg text-gray-500">
              Keep those around you informed so that you can focus
            </p>

            <dl class="mt-10 space-y-10">
              <div class="relative">
                <dt>
                  <p class="text-lg leading-6 font-medium text-gray-900">Reclaim your focus, no distractions</p>
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Send a link to your status page, for example, household members whilst working from home, so they are aware of when you will be available.
                </dd>
              </div>

              <div class="relative">
                <dt>
                  <p class="text-lg leading-6 font-medium text-gray-900">Deep work mode</p>
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Go deep, recap when done to avoid any further distractions in the future.
                </dd>
              </div>
            </dl>
          </div>

          <div class="mt-10 relative lg:mt-0 lg:col-start-1 p-2">
            <img class="relative mx-auto" width="924" src="@/assets/hp/featureStatus.png">
          </div>
        </div>
      </div>

      <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="relative">
          <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
            Make it yours, change layout and colors
          </h3>
          <p class="mt-3 text-lg text-gray-500">
            Customize it all, change it based on your flow
          </p>

          <dl class="mt-10 space-y-10">
            <div class="relative">
              <dt>
                <p class="text-lg leading-6 font-medium text-gray-900">Re-order your blocks</p>
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Some blocks need more attention than others. So put them where you can see them.
              </dd>
            </div>

            <div class="relative">
              <dt>
                <p class="text-lg leading-6 font-medium text-gray-900">Group your blocks</p>
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                With custom titles and styles of rows and columns, create groups to have a better visual overview. In addition, it helps with switching context.
              </dd>
            </div>

            <div class="relative">
              <dt>
                <p class="text-lg leading-6 font-medium text-gray-900">Change rows, columns and board background</p>
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Shuffle it all around, try a different layout each week until you find the one, experiment, find what works best for you. After all, it is your dashboard.
              </dd>
            </div>
          </dl>
        </div>

        <div class="mt-10 relative lg:mt-0 rounded-lg shadow-xl p-2 bg-white">
          <img class="relative mx-auto" width="800" src="@/assets/hp/featureBoard.gif">
        </div>
      </div>

      <div class="relative mt-12 sm:mt-16 lg:mt-24">
        <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div class="lg:col-start-2">
            <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Reports and insights
            </h3>
            <p class="mt-3 text-lg text-gray-500">
              Don't work more than you have to, keep healthy
            </p>

            <dl class="mt-10 space-y-10">
              <div class="relative">
                <dt>
                  <p class="text-lg leading-6 font-medium text-gray-900">Standard reports</p>
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Get an overview of where your hours went. Go back and forth in the week or month.
                </dd>
              </div>

              <div class="relative">
                <dt>
                  <p class="text-lg leading-6 font-medium text-gray-900">Identify scattered work across days</p>
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Learn about your work habits, how you work throughout the days on the timeline, and see when you usually take breaks or any other events.
                </dd>
              </div>
            </dl>
          </div>

          <div class="mt-10 relative lg:mt-0 lg:col-start-1 rounded-lg shadow-xl p-2 bg-white">
              <img class="relative mx-auto" width="924" src="@/assets/hp/featureReports.png">
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="bg-white">
    <div class="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8 text-center">
      <div class="pt-10 max-w-xl mx-auto px-4 sm:px-6 lg:px-8">
        <a
          href="https://app.getkairo.com/signup"
          class="inline-flex text-xl font-medium border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50"
          style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
          >
          Start trial
        </a>
      </div>
    </div>
  </div>

  <div class="bg-white">
    <div class="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
      <div class="px-6 py-6 bg-gray-100 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
        <div class="xl:w-0 xl:flex-1">
          <h2 class="text-2xl font-extrabold tracking-tight text-gray-700 sm:text-3xl">
            Get productivity tips right into your inbox.
          </h2>
          <p class="mt-3 max-w-3xl text-lg leading-6 text-gray-600">
            Sign up for our newsletter to stay up to date.
          </p>
        </div>
        <div class="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
          <form
                  name="newsletter"
                  method="post"
                  v-on:submit.prevent="handleSubmit"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  class="sm:flex">
            <label for="emailAddress" class="sr-only">Email address</label>
            <input v-model="formData.email" id="emailAddress" name="emailAddress" type="email" autocomplete="email" required
               class="w-full border-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-700 focus:ring-white rounded-md" placeholder="Enter your email">
            <button type="submit"
              class="flex ml-2 text-xl font-medium border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50"
              style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
            >
              Subscribe
            </button>
            <input type="hidden" name="form-name" value="newsletter" />
            <p class="hidden">
              <label>
                Don’t fill this out: <input name="bot-field" />
              </label>
            </p>
          </form>

          <p class="mt-3 text-sm text-gray-600">
            We care about the protection of your data. Read our
            <router-link to="/privacy-policy" class="text-gray-700 font-medium underline">
              Privacy Policy.
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>

  <footer class="bg-white">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div class="flex justify-center space-x-6 md:order-2">
        <a href="https://twitter.com/kairohq" target="_blank" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Twitter</span>
          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        </a>
      </div>
      <div class="mt-8 md:mt-0 md:order-1">
        <p class="text-left text-base text-gray-400">
          &copy; 2024 Sotak, Ltd. All rights reserved. Contact: support@getkairo.com | <router-link to="blog">Blog</router-link><br />
          <router-link to="terms">Terms of Service</router-link> | <router-link to="privacy-policy">Privacy Policy</router-link> | <router-link to="acceptable-use-policy">Acceptable Use Policy</router-link>
        </p>
      </div>
    </div>
  </footer>

</template>

<script>
  import { useToast } from "vue-toastification";
  import BlockFace from "../components/BlockFace";

  export default {
    name: "Home",
    components: {
      BlockFace
    },
    setup() {
      const toast = useToast();
      return { toast };
    },
    props: {
      msg: String
    },
    data() {
      return {
        freeAvailable: false,
        billing: true,
        formData: {},
        formAccessData: {},
        joinEarly: false,
        faces: [
          {text: 'default', value: 'default'},
          {text: 'cat', value: 'cat'},
          {text: 'bear', value: 'bear'},
          {text: 'owl', value: 'owl'},
          {text: 'humanbear', value: 'humanbear'},
          {text: 'hearts', value: 'hearts'},
          {text: 'money', value: 'money'},
          {text: 'argh', value: 'argh'}
        ],
      };
    },
    methods: {
      encode(data) {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&')
      },
      handleSubmit(e) {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: this.encode({
            'form-name': e.target.getAttribute('name'),
            ...this.formData,
          }),
        })
        .then(() => this.toast.success(`Thank you for being part of our journey! We will be in touch.`))
        .catch(() => this.toast.error(`Something went wrong. Please try again. :(`))
      },
      handleAccessSubmit(e) {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: this.encode({
            'form-name': e.target.getAttribute('name'),
            ...this.formAccessData,
          }),
        })
        .then(() => this.toast.success(`Thank you for your interest, we will be in touch.`))
        .catch(() => this.toast.error(`Something went wrong. Please try again. :(`))
      },
    }
  };
</script>
